@import 'antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner {
	display: inline-block;
	border-color: currentColor;
	border-style: solid;
	border-radius: 999999px;
	border-bottom-color: transparent;
	border-left-color: transparent;
	animation: spinnerSpin 0.45s linear infinite;
}

@keyframes spinnerSpin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
